<template>
  <v-dialog v-model="display" fullscreen @keydown.esc="close()">
    <common-confirm
      :title="confirm.title"
      :message="confirm.message"
      :confirm_dialog="confirm.dialog"
      :confirm_function="confirm.function"
      v-on:confirm="confirm.dialog = false"
      v-on:cancel="confirm.dialog = false"
    ></common-confirm>
    <!-- progress -->
    <common-loading
      :title="loading.title"
      :message="loading.message"
      :loading_dialog="loading.dialog"
      :show_loading="loading.show_loading"
      :show_confirm="loading.show_confirm"
      v-on:confirm="loading.dialog = false"
    >
    </common-loading>
    <!-- review dialog -->
    <v-dialog v-model="review.dialog" width="60%">
      <v-card>
        <v-row class="mx-4 mt-4">
          <v-col cols="4">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >審核通過？</label
            >
            <v-radio-group row v-model="review.status">
              <v-radio
                :ripple="false"
                v-for="(item, i) in review_status_manage_enum_str"
                :key="i + item"
                :label="item.text"
                :value="item.value"
                dense
              ></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="8">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >內容</label
            >
            <v-text-field
              hide-details
              outlined
              v-model="review.comment"
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                text-light-input
                mt-2
              "
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-card-actions class="mx-4">
          <v-btn
            elevation="0"
            :ripple="false"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-default
              py-2
              px-6
              me-2
              mt-6
              mb-2
            "
            color="#5e72e4"
            @click="closeReview()"
            >取消</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            :ripple="false"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-primary
              py-2
              px-6
              me-2
              mt-6
              mb-2
            "
            color="#5e72e4"
            @click="onReviewConfirm()"
            >確定</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="px-2 py-0">
      <v-card-title class="justify-center mx-4 pt-2 pb-0">
        <v-spacer></v-spacer>
        <v-img
          src="@/assets/img/GRT橫.jpg"
          class="navbar-brand-img ms-3"
          max-width="240"
          max-height="60"
        >
        </v-img>
        <!-- <span class="text-h4 justify-center bold"> {{ formTitle }}</span> -->
        <v-spacer></v-spacer>
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-2">
        <v-row>
          <v-col>
            <customer-add-account
              v-show="localMode === edit_mode_enum.Create"
              title="新增案件"
              btn_title="儲存"
              v-on:onAdd="onCreateCase"
              v-on:onClear="onClear"
            ></customer-add-account>
            <case-update-panel
              :title="getUpdateTitle()"
              :propCaseMode="propCaseMode"
              :propReview="
                (localItem.review_status == review_status_enum.Submitting ||
                  localItem.review_status == review_status_enum.Draft) &&
                isAllowApprove()
              "
              :propItemReviewStatus="localItem.review_status"
              v-show="localMode === edit_mode_enum.Edit"
              v-on:onUpdate="onUpdateClick"
              v-on:onRestore="onRestore"
              v-on:onExportWord="onExportWord"
              v-on:onExportPDF="onExportPDF"
              v-on:onReview="onReview"
            ></case-update-panel>
            <customer-close
              title="檢視案件"
              v-show="localMode === edit_mode_enum.View"
              v-on:onClose="close"
              v-on:onExportPDF="onExportPDF"
            ></customer-close>
            <case-basic-info
              ref="basic"
              :propEditMode="localMode"
              :info="localItem"
              :propCaseMode="propCaseMode"
              v-on:reload="reload"
            ></case-basic-info>
            <customer-add-account
              v-show="localMode === edit_mode_enum.Create"
              title="新增案件"
              btn_title="儲存"
              v-on:onAdd="onCreateCase"
              v-on:onClear="onClear"
            ></customer-add-account>
            <case-update-panel
              :title="getUpdateTitle()"
              :propCaseMode="propCaseMode"
              :propReview="
                (localItem.review_status == review_status_enum.Submitting ||
                  localItem.review_status == review_status_enum.Draft) &&
                isAllowApprove()
              "
              v-show="localMode === edit_mode_enum.Edit"
              v-on:onUpdate="onUpdateClick"
              v-on:onRestore="onRestore"
              v-on:onExportWord="onExportWord"
              v-on:onExportPDF="onExportPDF"
              v-on:onReview="onReview"
            ></case-update-panel>
            <customer-close
              title="檢視案件"
              v-show="localMode === edit_mode_enum.View"
              v-on:onClose="close"
              v-on:onExportPDF="onExportPDF"
            ></customer-close>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import CommonUtility from "@/util/CommonUtility.js";
import CaseBasicInfo from "./Widgets/CaseBasicInfo.vue";
import {
  defaultCase,
  object_type_enum,
  case_mode_enum,
  shelf_status_enum,
  review_status_enum,
  review_status_manage_enum_str,
  shelf_status_str,
} from "@/case.js";
import { edit_mode_enum } from "@/definitions.js";
import { mapGetters } from "vuex";
import CustomerAddAccount from "./Widgets/CustomerAddAccount.vue";
import CaseUpdatePanel from "./Widgets/CaseUpdatePanel.vue";
import CustomerClose from "./Widgets/CustomerClose.vue";
import CaseMixin from "@/components/CRM/CaseMixin.vue";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import CommonConfirm from "@/views/Pages/General/Widgets/CommonConfirm.vue";
import _ from "lodash";
export default {
  name: "case-detail",
  props: {
    propEditMode: {
      type: Number,
      default: 0,
    },
    propEditedItem: {
      type: Object,
      default: () => {},
    },
    display: {
      type: Boolean,
      default: false,
    },
    propCaseMode: {
      type: Number,
      default: 0,
    },
  },
  mixins: [CaseMixin],
  data: () => ({
    edit_mode_enum: edit_mode_enum,
    object_type_enum: object_type_enum,
    case_mode_enum: case_mode_enum,
    localMode: edit_mode_enum.View,
    shelf_status_enum: shelf_status_enum,
    review_status_enum: review_status_enum,
    review_status_manage_enum_str: review_status_manage_enum_str,
    shelf_status_str: shelf_status_str,
    localItem: {},
    originItem: {},
    defaultItem: defaultCase,
    loading: {
      dialog: false,
      title: null,
      message: null,
      show_loading: true,
    },
    confirm: {
      dialog: false,
      title: null,
      message: null,
      function: null,
    },
    review: {
      dialog: false,
      status: 3,
      comment: null,
      shelf_status: 0,
    },
  }),
  components: {
    CaseBasicInfo,
    CustomerAddAccount,
    CaseUpdatePanel,
    CustomerClose,
    CommonLoading,
    CommonConfirm,
  },
  mounted() {
    this.parseCaseInfo(this.propEditedItem);
    this.localMode = this.propEditMode;
  },
  watch: {
    propEditMode: function (val) {
      this.localMode = val;
    },
    propEditedItem: function (val) {
      this.parseCaseInfo(val);
    },
  },
  computed: {
    formTitle() {
      switch (this.localMode) {
        case edit_mode_enum.Create:
          return this.$t("Add Case");
        case edit_mode_enum.Edit:
          return this.getUpdateTitle();
        default:
          return this.$t("Case Detail");
      }
    },
    caseTitle() {
      if (this.localItem.type == "L") {
        if (this.localItem.serial_number != null) {
          return this.localItem.serial_number + "土地銷售表";
        }
        return "土地銷售表";
      } else if (this.localItem.type == "B") {
        if (this.localItem.serial_number != null) {
          return this.localItem.serial_number + "建物銷售表";
        }
        return "建物銷售表";
      }
      return "";
    },
  },
  methods: {
    ...mapGetters(["getToken", "isAllowAddCase", "isAllowApprove"]),
    parseCaseInfo(val) {
      this.localItem = _.cloneDeep(val);
      this.originItem = _.cloneDeep(this.localItem);
    },
    getUpdateTitle() {
      if (
        this.localItem != null &&
        this.localItem.shelf_status == shelf_status_enum.Draft
      ) {
        return "審核草稿";
      }
      return "編輯案件";
    },
    onRestore() {
      console.log("on restore", this.originItem);
      this.localItem = JSON.parse(JSON.stringify(this.originItem));
    },
    close() {
      this.onClear();
      this.localMode = edit_mode_enum.View;
      this.$emit("onClose");
    },
    onClear() {
      this.$refs.basic.clear();
      this.originItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.localItem = JSON.parse(JSON.stringify(this.defaultItem));
    },
    onCreateCase() {
      this.confirm = {
        dialog: true,
        title: "新增案件",
        message: "確認新增案件?",
        function: this.onCreateCaseConfirm,
      };
    },
    onCreateCaseConfirm() {
      const data = this.getInfo();
      // TODO do more check
      const msg = this.checkInfo(data);
      this.confirm.dialog = false;
      if (msg != null) {
        this.loading = {
          dialog: true,
          message: msg,
          show_confirm: true,
          show_loading: false,
        };
        return;
      }

      if (this.propCaseMode < case_mode_enum.Draft) {
        alert("無新增案件權限");
        return;
      }

      this.loading = {
        dialog: true,
        message: "新增案件中...",
        show_loading: true,
      };

      setTimeout(() => {
        if (this.propCaseMode == case_mode_enum.Draft) {
          this.createMyCase(data);
          // this.createCase(data);
        } else {
          this.createCase(data);
        }
      }, 100);
    },
    onCreateDone(data) {
      if (this.$refs.basic.hasPendingPhotos()) {
        let case_id = data.id;
        this.$refs.basic.uploadPhotos(case_id, true);
      } else {
        this.onAllCreateDone();
      }
    },
    onAllCreateDone() {
      this.loading = {
        dialog: false,
        message: "",
        show_loading: false,
      };
      this.onClear();
      this.$emit("onActionDone", "新增案件");
    },
    onCreateFail(data) {
      console.log("pares fail", data);
      if (
        data.code == "ERR_NETWORK" &&
        data.response &&
        data.response.data == undefined
      ) {
        this.loading = {
          dialog: true,
          message: "網路異常 新增案件失敗",
          show_loading: false,
          show_confirm: true,
        };
        return;
      }
      if (data.response && data.response.data && data.response.data.detail) {
        const msg = CommonUtility.parseError(data.response.data.detail);
        if (msg.length > 0) {
          this.loading = {
            dialog: true,
            message: msg,
            show_loading: false,
            show_confirm: true,
          };
          return;
        }
      }

      this.loading = {
        dialog: true,
        message: "新增案件失敗",
        show_loading: false,
        show_confirm: true,
      };
    },
    onUpdateDone(data) {
      if (this.$refs.basic.hasPendingPhotos()) {
        let case_id = data.id;
        this.$refs.basic.uploadPhotos(case_id, false);
      } else {
        this.onAllUpdateDone();
      }
    },
    onAllUpdateDone() {
      this.loading = {
        dialog: false,
        message: "",
        show_loading: false,
      };
      this.$emit("onActionDone", `更新案件:${this.localItem.name}`);
      this.onClear();
    },
    onUpdateDoneFail(data) {
      console.log("pares fail", data);
      if (
        data.code == "ERR_NETWORK" &&
        data.response &&
        data.response.data == undefined
      ) {
        this.loading = {
          dialog: true,
          message: "網路異常 更新案件失敗",
          show_loading: false,
          show_confirm: true,
        };
        return;
      }
      if (data.response && data.response.data && data.response.data.detail) {
        const msg = CommonUtility.parseError(data.response.data.detail);
        if (msg.length > 0) {
          alert(msg);
          return;
        }
      }

      this.loading = {
        dialog: true,
        message: "更新案件失敗",
        show_loading: false,
        show_confirm: true,
      };
    },
    onUpdateClick() {
      this.confirm = {
        dialog: true,
        title: "更新案件",
        message: "確認更新案件?",
        function: this.onUpdate,
      };
    },
    onUpdate() {
      // get the case id
      this.confirm.dialog = false;
      this.loading = {
        dialog: true,
        message: "更新案件中...",
        show_loading: true,
      };
      let case_id = this.$refs.basic.info.id;
      setTimeout(() => {
        if (this.propCaseMode == case_mode_enum.Draft) {
          this.updateMyCase(case_id);
        } else {
          this.updateCase(case_id);
        }
      }, 100);
    },
    getInfo() {
      // let case_info = JSON.parse(JSON.stringify(this.$refs.basic.case_info));
      let case_info = this.$refs.basic.getInfo();
      return case_info;
    },
    reload(created) {
      console.log("reload", created);
      if (created) {
        this.onAllCreateDone();
      } else {
        this.onAllUpdateDone();
      }
      let case_id = this.$refs.basic.info.id;
      console.log("reload case ", case_id);
      this.$emit("onReload", case_id);
    },
    onExportWord() {
      // this.doCaseExport();
      this.onExport("docx");
    },
    onExportPDF() {
      // this.doCaseExport("pdf");
      this.onExport("pdf");
    },
    onExport(type) {
      // this.exporting = true;
      this.loading = {
        dialog: true,
        message: `匯出${this.caseTitle}`,
        show_loading: true,
        show_confirm: false,
      };

      let case_id = this.localItem.id;
      console.log("export case ", case_id);
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/case-exports/`;
      let data = {
        case_id: case_id,
        lbtype: this.localItem.type,
        type: type,
      };
      this.doHttpPost(url, data, this.onExportDone, null, this.onExportFinal);
    },
    onExportDone(data) {
      console.log(data);
      // {status: 1, filename: 'A0003土地銷售表.docx', path: 'u1#A0003土地銷售表_d0c021fc-05ff-4619-b198-6ce11eb0cae1.docx'}
      const filename = data["filename"];
      const path = data["path"];
      console.log("force download ", filename, path);
      this.download(filename, path);
    },
    onExportFinal() {
      this.loading = {
        dialog: false,
        message: ``,
        show_loading: false,
        show_confirm: false,
      };
    },
    download(filename, path) {
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/case-exports/download`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.getToken(),
        },
        responseType: "blob", // important
        params: { filename: filename, path: path },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.forceFileDownload(response, filename);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    onReview() {
      //console.log("show review dialog");
      this.review.dialog = true;
    },
    closeReview() {
      this.review.dialog = false;
      this.review.status = 3;
      this.review.comment = null;
      this.review.shelf_status = null;
    },

    onReviewConfirm() {
      console.log("update review status");
      this.$refs.basic.setReviewResult(this.review.status, this.review.comment);
      this.onUpdate();
      this.closeReview();
    },
  },
};
</script>
<style scoped>
/* @import "../../../assets/css/jub.css"; */

.download_txt:hover {
  color: blue !important;
  text-decoration: underline;
}
</style>
