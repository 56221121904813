<template>
  <v-card class="card-shadow border-radius-xl mt-2">
    <div class="px-6 pt-2">
      <h4 class="text-h4 font-weight-bold text-grt">
        {{ title }}
      </h4>
    </div>
    <div class="px-2 pb-5 pt-2">
      <div class="d-md-flex justify-end">
        <v-btn
          :ripple="false"
          elevation="0"

          @click="onReviewClick"
          class="
            font-weight-bolder
            py-5
            px-6
            ms-2 ms-auto
            btn-primary
            bg-gradient-grt
          "
          small
          v-if="propReview && propItemReviewStatus == review_status_enum.Submitting"
        >
          一般審核
        </v-btn>
        <v-btn
          :ripple="false"
          elevation="0"

          @click="onReviewClick"
          class="
            font-weight-bolder
            py-5
            px-6
            ms-2 ms-auto
            btn-primary
            bg-gradient-grt
            force_audit
          "
          small
          v-if="propReview && propItemReviewStatus == review_status_enum.Draft"
        >
          強迫審核
        </v-btn>
        <v-btn
          v-else
          :ripple="false"
          elevation="0"
          color="#fff"
          @click="onResetClick"
          class="font-weight-bolder btn-outline-secondary py-5 px-6 ms-2"
          small
        >
          {{ $t("Reset") }}
        </v-btn>

        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-primary
            bg-gradient-primary
            py-5
            px-6
            ms-2
          "
          small
          @click="onUpdateClick"
        >
          {{ $t("Update") }}
        </v-btn>
        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-primary
            bg-gradient-default
            py-5
            px-6
            ms-2
          "
          small
          @click="onExportPDF"
        >
          匯出PDF
        </v-btn>
        <v-btn
          v-if="propCaseMode === case_mode_enum.Manage && isAllowExportWordCase()"
          :ripple="false"
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-primary
            bg-gradient-success
            py-5
            px-6
            ms-2
          "
          small
          @click="onExportWord"
        >
          匯出Word
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { case_mode_enum,review_status_enum } from "@/case.js";
export default {
  name: "case-update",
  props: {
    title: {
      type: String,
      default: "更新",
    },
    propCaseMode: {
      type: Number,
      default: 0,
    },
    propReview: {
      type: Boolean,
      default: false,
    },
    propItemReviewStatus:{
      type: Number,
      default: 0
    }
    
  },
  data() {
    return {
      case_mode_enum: case_mode_enum,
      review_status_enum:review_status_enum,
    };
  },
  methods: {
    ...mapGetters(["isAllowExportWordCase"]),
    onUpdateClick() {
      this.$emit("onUpdate");
    },
    onResetClick() {
      this.$emit("onRestore");
    },
    onExportPDF() {
      this.$emit("onExportPDF");
    },
    onExportWord() {
      this.$emit("onExportWord");
    },
    onReviewClick() {
      this.$emit("onReview");
    },
  },
};
</script>

<style scoped>
.force_audit {
  color :red!important;
  border: thin solid red !important;;
}
</style>